const messages = {
  homevall:{
    homevall1: "เชิญ",
    homevall2: "เชื่อมต่อแล้ว",
    homevall3: "เชื่อมต่อ",
    homevall4: "หน้าหลัก",
    homevall5: "ตลาด",
    homevall6: "ขุด",
    homevall7: "เชิญ",
    homevall8: "เชื่อมต่อสำเร็จ",
    homevall9: "เชื่อมต่อล้มเหลว",
    homevall10: "ยืนยันการผูก",
    homevall11: "ยกเลิก",
    homevall12: "ยืนยัน",
    homevall12_1: "สินเชื่อจำนำ",
    homevall12_2: "โลกสัตว์เลี้ยง",
    homevall12_3: "การแข่งขันกีฬา",
    homevall12_4: "ยืนยัน",
    homevall12_5: "เกมและบันเทิง"
  },
  home: {
    home1: "อัตราผลตอบแทนจากการขุด",
    home2: "เงินฝาก",
    home3: "ป้อนจำนวนเงินที่แลกเปลี่ยน",
    home4: "ยอดคงเหลือในบัญชี",
    home5: "จำนวนเงินที่แลกเปลี่ยน",
    home6: "แลกเปลี่ยนทันที",
    home7: "กำลังขุด",
    home8: "อัตราผลตอบแทนจากการขุด",
    home9: "เปิดใช้งาน",
    home10: "เหมืองแร่",
    home11: "ผลผลิตรวมของการขุด DBTC",
    home12: "อัตราผลตอบแทนสุทธิ",
    home13: "ผลผลิตแล้ว",
    home14: "คงเหลือ",
    home15: "ขีดจำกัดทั้งหมด",
    home16: "กำลังขุดทั้งหมด",
    home17: "โทเค็นการขุด",
    home18: "สกุลเงินเดี่ยว",
    home19: "สกุลเงินทั้งหมด",
    home20: "สกุลเงิน LP",
    home21: "เลือกโทเค็น",
    home22: "สกุลเงิน",
    home23: "ผลตอบแทนจากการขุด",
    home32: "ดูรายงานความปลอดภัย",
    home33: "ข้อมูล",
    home34: "ยังไม่เปิดใช้งาน",
    home35: "รายละเอียด",
    home36: "สัดส่วนการเผาไหม้โดยตรง",
    home37: "สัดส่วนการเพิ่มสภาพคล่อง",
    home38: "สัดส่วนของโครงการ",
    home39: "สัดส่วนการซื้อ DBTC เพื่อเผาไหม้",
    home40: "อนุญาตสำเร็จ",
    home41: "อนุญาตล้มเหลว",
    home42: "กรุณาใส่",
    home43: "ฝากสำเร็จ",
    home44: "ฝากล้มเหลว",
    home45: "โปรดเชื่อมต่อกระเป๋าเงิน"
  }

  ,
  bazaar: {
    bazaar1: "ยอดเงินคำมั่นสัญญาทั้งหมด",
    bazaar2: "ตลาดทั้งหมด",
    bazaar3: "สกุลเงิน",
    bazaar4: "ยอดเงินฝากทั้งหมด",
    bazaar5: "อัตราจำนำ",
    bazaar6: "สภาพคล่อง",
    bazaar7: "อัตราดอกเบี้ยเงินฝาก",
    bazaar8: "ปัจจัยจำนำ",
    bazaar9: "อันดับการฝากเงิน",
    bazaar10: "ดูรายงานความปลอดภัย",
    bazaar11: "ผ่านการตรวจสอบความปลอดภัยจากบริษัทตรวจสอบชื่อดัง Beosin"
  }
  ,
  ore:{
    ore1: "พลังการขุดเครือข่ายเรียลไทม์",
    ore2: "ปริมาณ DBTC ที่เหลือทั้งหมดในเครือข่าย",
    ore3: "พลังการขุดเครือข่ายรายวัน",
    ore4: "การผลิต DBTC เครือข่ายรายวันทั้งหมด",
    ore5: "การผลิตรายวันปัจจุบัน",
    ore6: "พลังการขุดทั้งหมดของฉัน",
    ore7: "พลังการขุดจากเงินฝาก",
    ore8: "รอรับ",
    ore9: "รับ",
    ore10: "ได้รับ",
    ore11: "อันดับพลังการขุดส่วนบุคคล",
    ore12: "บอร์ดตำนาน",
    ore13: "โบนัสเครือข่ายสะสมทั้งหมด",
    ore14: "โบนัสที่รอรับ",
    ore15: "อันดับท็อป 10 มีโอกาสรับโบนัสค่าธรรมเนียม",
    ore16: "อันดับ",
    ore17: "ที่อยู่",
    ore18: "พลังการขุด",
    ore19: "สัดส่วน",
    ore20: "เชิญเพื่อนเข้าร่วมการซื้อและรับ NFT",
    ore21: "เงินฝากทีม",
    ore22: "บันทึกการรับ",
    ore23: "กำลังขุดที่ฉันแนะนำ",
    ore24: "เริ่มการผลิตในวันถัดไปหลังจากสร้างกำลังขุดและต่อเนื่อง 7 วัน",
    ore25: "ปัจจัยกำลังขุดวันนี้",
    ore26: "ไม่มีที่สามารถรับได้",
    ore27: "รับสำเร็จ",
    ore28: "พลังการขุดในการขายส่วนตัว",
    ore29: 'ราคาเปิดตลาดวันนี้',
    ore30: 'อัตราการเพิ่มขึ้น',
    ore31: 'ราคาปัจจุบัน',


  },
  my:{
    my1: "เชิญเพื่อน",
    my2: "รับพลังการขุดสูง",
    my3: "พลังการขุดที่คุณได้รับ",
    my4: "ผู้เชิญของคุณ",
    my5: "คัดลอกลิงก์เชิญ",
    my6: "คนที่คุณเชิญ",
    my7: "ขยาย",
    my8: "ย่อ",
    my9: "อันดับ",
    my10: "ที่อยู่",
    my11: "สัดส่วน",
    my12: "คน",
    my13: "กำลังขุด",
    my14: "พลังการขุดที่ไม่ได้เปิดใช้งาน",
    my15: "พลังการขุดแนะนำที่เปิดใช้งานแล้ว"

  },
  record:{
    record1: "บันทึกการรับ",
    record2: "กำลังขุดที่ผลิตได้ในวันนี้",
    record3: "พลังการขุดจากเงินฝากประจำวัน"
  }
}

export default messages
