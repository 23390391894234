const messages = {
  homevall:{
    homevall1: "Invite",
    homevall2: "Connected",
    homevall3: "Connect",
    homevall4: "Home",
    homevall5: "Market",
    homevall6: "Mining",
    homevall7: "Invite",
    homevall8: "Connection Successful",
    homevall9: "Connection Failed",
    homevall10: "Confirm Binding",
    homevall11: "Cancel",
    homevall12: "Confirm",
    homevall12_1: "Collateral Lending",
    homevall12_2: "Pet World",
    homevall12_3: "Sports Competition",
    homevall12_4: "Confirm",
    homevall12_5: "Game Entertainment"
  },
  home: {
    home1: "Mining Annual Rate",
    home2: "Deposit",
    home3: "Enter Exchange Amount",
    home4: "Account Balance",
    home5: "Exchange Amount",
    home6: "Exchange Now",
    home7: "Mining Power",
    home8: "Mining Annual Rate",
    home9: "Activate",
    home10: "Mine",
    home11: "Total DBTC Mining Output",
    home12: "Net Yield",
    home13: "Produced",
    home14: "Remaining",
    home15: "Total Quota",
    home16: "Total Mining Power",
    home17: "Mining Token",
    home18: "Single Currency",
    home19: "All Currencies",
    home20: "LP Currency",
    home21: "Select Token",
    home22: "Currency",
    home23: "Mining Earnings",
    home32: "View Security Report",
    home33: "Data",
    home34: "Not Open",
    home35: "Details",
    home36: "Direct Burn Ratio",
    home37: "Increase Liquidity Ratio",
    home38: "Project Team Ratio",
    home39: "Purchase DBTC Burn Ratio",
    home40: "Authorization Successful",
    home41: "Authorization Failed",
    home42: "Please Enter",
    home43: "Deposit Successful",
    home44: "Deposit Failed",
    home45: "Please Connect Wallet"
  }

  ,
  bazaar: {
    bazaar1: "Total Pledge Amount",
    bazaar2: "All Markets",
    bazaar3: "Currency",
    bazaar4: "Total Deposits",
    bazaar5: "Pledge Rate",
    bazaar6: "Liquidity",
    bazaar7: "Deposit Rate",
    bazaar8: "Collateral Factor",
    bazaar9: "Deposit Ranking",
    bazaar10: "View Security Report",
    bazaar11: "Passed Security Audit by Renowned Audit Firm Beosin"
  }
,
  ore:{
    ore1: "Total Network Real-Time Hashrate",
    ore2: "Total Remaining DBTC Production in the Network",
    ore3: "Total Network Daily Hashrate",
    ore4: "Total Network Daily DBTC Production",
    ore5: "Current Daily Production",
    ore6: "My Total Hashrate",
    ore7: "Deposit Hashrate",
    ore8: "Pending",
    ore9: "Claim",
    ore10: "Claimed",
    ore11: "Personal Hashrate Leaderboard",
    ore12: "Hall of Fame",
    ore13: "Total Network Cumulative Bonus",
    ore14: "Pending Bonus",
    ore15: "Top 10 Leaderboard Has Chance to Earn Fee Bonus",
    ore16: "Rank",
    ore17: "Address",
    ore18: "Hashrate",
    ore19: "Share",
    ore20: "Invite Friends to Participate and Earn NFTs",
    ore21: "Team Deposit",
    ore22: "Claim History",
    ore23: "My Referral Hash Power",
    ore24: "Production starts the day after hash power is generated and continues for 7 days",
    ore25: "Today's Hash Power Factor",
    ore26: "Nothing to Claim",
    ore27: "Claim Successful",
    ore28: "Private Sale Hash Power",
    ore29: 'Today\'s Opening Price',
    ore30: 'Price Increase',
    ore31: 'Current Price',


  },
  my:{
    my1: "Invite Friends",
    my2: "Earn High Hashrate",
    my3: "Hashrate You Have Earned",
    my4: "Your Referrer",
    my5: "Copy Invitation Link",
    my6: "People You Have Invited",
    my7: "Expand",
    my8: "Collapse",
    my9: "Ranking",
    my10: "Address",
    my11: "Share",
    my12: "People",
    my13: "Hash Power",
    my14: "Inactive Hash Power",
    my15: "Activated Recommended Hash Power"

  },
  record:{
    record1: "Claim History",
    record2: "Today's Hash Power Output",
    record3: "Daily Deposit Hashrate"
  }
}

export default messages
